.expandable-card {
  margin-top: 15px;

  dl {
    border-width: 1px 0 0 0;
  }
}

.expandable-card-title {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
}

.expandable-card-body {
  border: 1px solid black;
  border-radius: 4px;
  margin-bottom: 10px;
}
