.project-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  grid-template-areas:
    "header header"
    "sidebar main";
}

.project-details-header {
  grid-area: header;
  display: flex;
  align-items: center;
}

.project-title {
  flex-grow: 1;
}

.project-details-header {
  min-width: 10%;
}

.project-details-summary {
  grid-area: sidebar;
  margin-right: 15px;
}

.project-details .card-container {
  grid-area: main;
}

.notes {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

/* ***** Contract Card Styles ***** */
.contract-name {
  margin-left: 15px;
  flex-grow: 1;
}

.phase-table {
  margin: 5px;
}
