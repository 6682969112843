.invoiced-since {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "header"
    "main";
}

.invoiced-since-header {
  grid-area: header;
  display: flex;
  align-items: center;
}

.invoiced-since-title {
  flex-grow: 1;
}

.invoiced-since-main {
  grid-area: main;
}
