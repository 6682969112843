.description-column {
  width: 60ch;
}

.override-text {
  width: 100%;
}

.send-to-bpo-header {
  display: flex;
  align-items: center;
}

.send-to-bpo-header-title {
  flex-grow: 1;
}
