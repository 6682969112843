/* *** Generic Styles *** */

body {
  padding: 0 25px;
}

/* ***** Generic Table Styles ***** */
table {
  width: 100%;
  border-top: 1px solid black;
}

table.no-top-border {
  border-top: 0px;
}

tbody tr:nth-child(odd) {
  background-color: #ccc;
}

/* ***** Generic Data List Styles ***** */
dl {
  display: flex;
  flex-flow: row wrap;
  border: solid #333;
  border-width: 1px 1px 0 0;
}

dt {
  flex-basis: 30%;
  padding: 2px 4px;
  background: #333;
  text-align: right;
  color: #fff;
}

dd {
  flex-basis: 60%;
  flex-grow: 1;
  margin: 0;
  padding: 2px 4px;
  border-bottom: 1px solid #333;
}

/* ***** Generic Nav Styles ***** */
.link-list {
  display: flex;
  list-style-type: none;
  padding-left: 0;

  .link {
    margin-left: 15px;
    margin-right: 15px;

    * {
      margin: 0;
    }
  }
}

/* ***** Generic Input Styles ***** */
input, select, button {
  margin: 10px;
}

input, textarea {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  box-sizing: border-box;
}

/* ***** Text Alignment Helpers ****** */

.center-align-text {
  text-align: center;
}

.left-align-text {
  text-align: left;
}

.right-align-text {
  text-align: right;
}

/* ***** Text Styling Helpers ***** */
.bold-text {
  font-weight: bold;
}

.red-text {
  color: red;
}

/* ***** Background Color Helpers ***** */
.green-background {
  background-color: lightgreen;
}

.yellow-background {
  background-color: yellow;
}

.orange-background {
  background-color: orange;
}

/* ***** Generic Styling Helpers ***** */
.hidden {
  display: none;
}

.invalid {
  outline: 2px solid rgb(185, 31, 31);
}

.margin {
  margin: 5px;
}

/* ***** Flexbox Helpers ***** */
.flex-grow-1 {
  flex-grow: 1;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}