.unsent-auths {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "header"
    "main";
}

.unsent-auths-header {
  grid-area: header;
  display: flex;
  align-items: center;
}

.unsent-auths-title {
  flex-grow: 1;
}

.unsent-auths-cards {
  grid-area: main;
}
