.billing-request-details {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
  grid-template-areas:
    "header header"
    "main sidebar";
}

.billing-request-details-header {
  grid-area: header;
  display: flex;
  align-items: center;
}

.billing-request-title {
  flex-grow: 1;
}

.udi-step {
  border: 1px solid black;
  margin-bottom: 10px;

  dl {
    border-right-width: 0;
    margin-bottom: 0;
  }

  h3 {
    margin-left: 10px;
  }
}

.billing-request-details-sidebar {
  grid-area: sidebar;
}

.billing-request-details-main {
  grid-area: main;
}
