.landing-page {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "header header"
    "pmtitle bpotitle"
    "contracts wreceipts"
    "contracts woreceipts"
    "financial .";
  column-gap: 10px;
  row-gap: 10px;
}

.landing-page-header {
  grid-area: header;
  display: flex;
  align-items: center;
}

.landing-page-title {
  flex-grow: 1;
}

.pm-title {
  grid-area: pmtitle;
}

.bpotitle {
  grid-area: bpotitle;
}

.contract-status-summary-card {
  grid-area: contracts;
}

.financial-summary-card {
  grid-area: financial;
}

.with-receipts-card {
  grid-area: wreceipts;
}

.without-receipts-card {
  grid-area: woreceipts;
}
