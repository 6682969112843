.configure-template {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "header"
    "main"
    "actions";
}

.configure-template-header {
  grid-area: header;
  display: flex;
  align-items: center;
}

.contract-title {
  flex-grow: 1;
}

.configure-template-summary {
  grid-area: main;
}

.configure-template-actions {
  grid-area: actions;
  display: flex;
  justify-content: right;
  margin-top: 10px;

  button {
    margin-left: 10px;
  }
}
