.discrepancies {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "header"
    "main";
}

.discrepancies-header {
  grid-area: header;
  display: flex;
  align-items: center;
}

.discrepancies-title {
  flex-grow: 1;
}

.discrepancies-cards {
  grid-area: main;
}

.discrepancy-card-body {
  display: flex;
  gap: 10px;
}

.discrepany-contract-summary {
  width: 50%;
}

.discrepancy-reconciliation {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 50%;
}

.discrepancy-adjustment-note {
  width: 100%;
}
