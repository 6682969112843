.billing-template-data {
  display: flex;
  flex-direction: column;
}

.billing-template-field {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;

  span {
    width: 23ch;
  }

  input, textarea, select, svg {
    flex-grow: 1;
    margin: 0 10px;
  }
}

.billing-template-actions {
  display: flex;
  justify-content: space-evenly;

  button {
    margin: 10px;
  }
}

.disabled-template {
  text-decoration: line-through;
  margin-left: 10px;
}

.template-card-title {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
}

.template-card-body {
  border: 1px solid black;
  border-radius: 4px;
  margin-bottom: 10px;
}
